/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/scss/_base.scss"
import "./src/scss/_normalize.scss"
import "./src/scss/UI/_ui.scss"
